import React from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const Contact = () => {
  return (
    <>
        <div className='container' >
            <div className='row' style={{padding:"62px 0px",rowGap:"40px"}}  >
                <div className='col-12' >
                <h1 className='about-section-heading text-center' >CONTACT</h1>
                </div>

                <div className='col-lg-4 col-md-5 col-sm-12' style={{background:"#f9f9fa",padding:"32px 0px",borderBottom:"3px solid #16df7e",borderTop:"3px solid #16df7e"}} >
                  <div className='container' >   
                    <div className='row' >
                      <div className='col-2' >
                        <svg xmlns="http://www.w3.org/2000/svg" className='p-1' width="34" height="34" viewBox="0 0 24 24" style={{fill: "#16df7e",background:"#fff",borderRadius:"50px"}}><path d="M11.42 21.815a1.004 1.004 0 0 0 1.16 0C12.884 21.598 20.029 16.44 20 10c0-4.411-3.589-8-8-8S4 5.589 4 9.996c-.029 6.444 7.116 11.602 7.42 11.819zM12 4c3.309 0 6 2.691 6 6.004.021 4.438-4.388 8.423-6 9.731-1.611-1.308-6.021-5.293-6-9.735 0-3.309 2.691-6 6-6z"></path><path d="M11 14h2v-3h3V9h-3V6h-2v3H8v2h3z"></path></svg>
                      </div>

                      <div className='col-10' >
                          <h6 className='mb-0' style={{fontSize:"22px",fontWeight:500,color:"#5f687b",}} >Location:</h6>
                          <p style={{color:"#949cac",fontSize:"14px"}} >101 Chanda Tower, Vaishali Nagar, Jaipur - 302021</p>
                      </div>

                      <div className='col-2' >
                        <svg xmlns="http://www.w3.org/2000/svg" className='p-1' width="34" height="34" viewBox="0 0 24 24" style={{fill: "#16df7e",background:"#fff",borderRadius:"50px"}}><path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z"></path></svg>
                      </div>

                      <div className='col-10' >
                          <h6 className='mb-0' style={{fontSize:"22px",fontWeight:500,color:"#5f687b",}} >Email:</h6>
                          <p style={{color:"#949cac",fontSize:"14px"}} >info@rnsitsolutions.com</p>
                      </div>

                      <div className='col-2' >
                        <svg xmlns="http://www.w3.org/2000/svg" className='p-1' width="34" height="34" viewBox="0 0 24 24" style={{fill: "#16df7e",background:"#fff",borderRadius:"50px"}}><path d="M16.75 2h-10c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-10 18V4h10l.002 16H6.75z"></path><circle cx="11.75" cy="18" r="1"></circle></svg>
                      </div>

                      <div className='col-10' >
                          <h6 className='mb-0' style={{fontSize:"22px",fontWeight:500,color:"#5f687b",}} >Call:</h6>
                          <p style={{color:"#949cac",fontSize:"14px"}} >+917887940832</p>
                      </div>

                    </div>
                  </div>
                </div>
                <div className='col-lg-7 mx-auto h-100 col-md-7 col-sm-12 px-3' style={{background:"#f9f9fa",padding:"32px 0px",borderBottom:"3px solid #16df7e",borderTop:"3px solid #16df7e"}} >
                <Form>
      <Row className="mb-3" style={{rowGap:"10px",}} xs={1} lg={2} md={2} sm={1} >
        <Form.Group as={Col} controlId="formGridName">
          <Form.Label>Your Name</Form.Label>
          <Form.Control type="text" />
        </Form.Group>

        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="formGridSubject">
        <Form.Label>Subject</Form.Label>
        <Form.Control  />
      </Form.Group>

      <FloatingLabel controlId="floatingTextarea2" label="Comments">
        <Form.Control
          as="textarea"
          placeholder="Leave a comment here"
          style={{ height: '100px' }}
        />
      </FloatingLabel>
      <div className='d-flex justify-content-center mt-4' >
      <button className='contact-btn' >Send Message</button>
      </div>
    </Form>

                </div>

            </div>

        </div>
    </>
  )
}

export default Contact
