import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../index.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

const Testimonials = () => {
  const testimonialData = [
    {
      message:
        "App is quite easy to use and very convenient to hande and supervise agents",
      url: require("../assets/asset 6.jpeg"),
      name: "Soni Associates",
    },
    {
      message:
        "App is quite fast and easy to use",
      url: require("../assets/asset 7.jpeg"),
      name: "Mp Associates",
    },
    {
      message:
        "Easy to use and awesome performance",
      url: require("../assets/asset 8.jpeg"),
      name: "Shree Durga Associates",
    },
    {
      message:
        "Satisfied with the app",
      url: require("../assets/asset 9.jpeg"),
      name: "Kasana Associates",
    },
    {
      message:
        "Happily Satisfied with the app",
      url: require("../assets/asset 10.jpeg"),
      name: "Aash Enterprises",
    },
    {
      message:
        "Satisfied with the Application",
      url: require("../assets/asset 11.jpeg"),
      name: "Mahalaxmi Repo Agencies",
    },
    {
      message:
        "Happy with the user interface and quite satisfied with the various features that enables user to handle all the repo agents and office staff at simultaneously",
      url: require("../assets/asset 12.jpeg"),
      name: "JaiMataDi Enterprises",
    },
    {
      message:
        "Satisfied with the app as everything running smoothly",
      url: require("../assets/asset 13.jpeg"),
      name: "Mukesh Kumar Singh Repo Agency",
    },
    {
      message:
        "Quite satisfied with the app is working fine",
      url: require("../assets/asset 3.jpeg"),
      name: "On The Spot Collection Agency",
    },
    {
      message:
        "Simple interface and easy to use. Quite easy to track repo agents.",
      url: require("../assets/asset 4.jpeg"),
      name: "BMR Associates",
    },
    {
      message:
        "Satisfied with the app as everything is fine. Online search is awesome.",
      url: require("../assets/asset 5.jpeg"),
      name: "Shiv Motors repo Agency",
    },
];
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12" style={{padding:"62px 0px"}} >
            <h1 className="testimonial-section-heading text-center mb-3">
              Testimonials
            </h1>

            <Swiper
      style={{ height: "40vh", cursor: "pointer" }}
      centeredSlides={true}
      loop={true}
      autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
      slidesPerView={'auto'}
    //   pagination={{
    //       clickable: true,
    //     }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
      modules={[Autoplay, Pagination, Navigation]}
      freeMode={true}
      className="mySwiper"
    >
      {testimonialData.map((data, index) => (
        <SwiperSlide key={index}>
          <div className="card border-0 p-3">
            <p className="mb-0">
              {data.message}
            </p>
          </div>
          <img
            src={data.url}
            alt="as"
            style={{
              borderRadius: "50%",
              width: "90px",
              zIndex: 2,
              border: "6px solid #fff",
              position: "relative",
              bottom: "13px",
            }}
          />
          <h3
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "#111",
            }}
          >
            {data.name}
          </h3>
        </SwiperSlide>
      ))}
    </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
