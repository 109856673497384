import React from "react";
import "../../index.css"

const RequestDemo = () => {
  return (
    <>
      <div className="container-fluid" style={{background:"#16df7e"}} >
        <div className="row">
          <div className="col-12 text-center cta" style={{padding:"62px 0px"}} >
            <h3 className="text-center" >Request For Demo</h3>
            <p style={{marginTop:"10px"}} >
            Want to expand your business, making vehicle recovery easier? 
            <br/>
            Join us and be a part of recovery masters family
            </p>
            <span className="cta-btn" style={{textDecoration:"none",cursor:"pointer"}} >REQUEST FOR DEMO</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestDemo;
