import React from "react";
import Navigation from "../Navigation";
import HeroSection from "./HeroSection";
import AboutUsSection from "./AboutUsSection";
import Counter from "./Counter";
import Features from "./Features";
import "../../index.css"
import Testimonials from "./Testimonials";
import RequestDemo from "./RequestDemo";
import WhyUs from "./WhyUs";
import Contact from "./Contact";
import Footer from "./Footer";

const Home = () => {
  return (
    <>
      <div className="container-fluid w-100" style={{background:"white"}} >
        <div className="row p-0">
          <div className="col-12 p-0">
            {/* navbar started  */}
            <Navigation/>
            {/* navbar ended  */}

            {/* hero section started  */}
                <HeroSection/>
            {/* hero section ended  */}

            {/* about us section started */}
                <AboutUsSection/>
            {/* about us section ended */}

            {/* counter section started  */}
                <Counter/>
            {/* counter section ended  */}

            {/* features & testimonial section started  */}
              <div style={{background:"#f9f9fa"}} >
                  <Features/>
                  {/* <Testimonials/> */}
              </div>
            {/* features & testimonial section ended  */}

            {/* request for demo section started  */}
              <RequestDemo/>
            {/* request for deom section ended  */}

            {/* why choose us section started  */}
                <WhyUs/>
            {/* why choose us section ended  */}

            {/* contact section started  */}
              <Contact/>
            {/* contact section ended  */}

            {/* header section started  */}
              <Footer/>
            {/* header section ended  */}

          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
