import React, { useState } from "react";
import "../../index.css";
import heroImg from "../assets/asset 1.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const HeroSection = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [email,setEmail] = useState("");
  const [name,setName] = useState("");
  const [phone,setPhone] = useState("");

  const deleteUser = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        agency_name: name,
        email: email,
        phone_no: phone,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        "https://masterapi.recoverymasters.in/add/request/to/delete/user",
        requestOptions
      );
      const result = await response.json();
      if(result.status==1){
        handleClose();
        toast.success(result.msg)
        setEmail("");
        setName("");
        setPhone("");

      }else if(result.status==0){
        toast.error(result.validationerror[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container" style={{ height: "80vh" }}>
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-12 hero-section-left">
            <h1 className="hero-welcome" style={{ color: "#444444" }}>
              WELCOME
              <br />
              <span style={{ color: "#16df7e" }}>RECOVERY MASTERS</span>
            </h1>
            <h5
              className="mt-2"
              style={{
                fontFamily: "Raleway",
                fontSize: "1.25rem",
                fontWeight: 500,
                lineHeight: "1.2",
              }}
            >
              It is a solution which provide a common platform to all the banks,
              NBFC and their authorized repossession agencies.
            </h5>
            <button className="hero-section-button py-2 px-4 mt-2">
              Request For Demo
            </button>
            <button
              className="hero-section-button py-2 px-4 mt-2 ms-3"
              onClick={handleShow}
            >
              Request for Delete
            </button>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12 hero-section-right ">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={heroImg}
                className="w-75 hero-section-img-animate"
                alt="hero-section-img"
              />
            </div>
          </div>
        </div>
      </div>

      {/* delete modal started  */}
      <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupAgencyName">
              <Form.Label>Agency Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Name" onChange={(e)=>setName(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" onChange={(e)=>setEmail(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPhone">
              <Form.Label>Phone No</Form.Label>
              <Form.Control
                type="number"
                placeholder="Phone Number"
                onChange={(e)=>setPhone(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete modal ended  */}
      <ToastContainer position="top-right" autoClose={5000} />
    </>
  );
};

export default HeroSection;
