import React from 'react'
import "../../index.css"
import aboutUsImg from "../assets/asset 2.png"

const AboutUsSection = () => {
  return (
    <>
        <div className='container'>
        <div className='row h-100 align-items-center justify-content-center' >
            

            <div className='col-lg-6 col-md-6 col-sm-12' >
            <div className='d-flex align-items-center justify-content-center' >
                <img src={aboutUsImg} className='w-100 h-100'  alt='about-section-img' />
            </div>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12' >
                <h1 className='about-section-heading' >About Us</h1>
                <p>Recovery Masters is a solution which provide a common platform to all the banks, NBFC and their authorized repossession agencies. The vehicle repossession business is a segment in India facing many challenges, which makes the execution a bit difficult. Surprisingly, the business owners were themselves not aware that having technology into the system can make their job easy and way more effective.</p>
                <p>
                On starting the conversation in this business was itself a challenge for us because they were used to of this legacy system so much, that any change in the process was scary and forcing them to come out from their comport zone. Recovery Masters started having this conversation with the business owner and slowly we were able to make them understand that having a system like Recovery Masters can make their life easy and business more profitable.. Recovery Masters Solution will be a 3rd party solution for Banks and NBFC's. Our solution will be a middle layer system through which all the authorized vehicle recovery agencies. Having this system will provide the complete traceability to the banks, and NBFC's about recovery agencies' activities.
                </p>
            </div>

        </div>
    </div>
    </>
  )
}

export default AboutUsSection
