import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import PrivacyPolicy from './components/Home/PrivacyPolicy';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/Privacy-Policies' element={<PrivacyPolicy/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;