import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../index.css"

const Navigation = () => {
  return (
    <>
     <Navbar expand="lg" className="bg-body-tertiary shadow-lg fixed-top">
              <Container>
                <Navbar.Brand >Recovery Masters</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>Home</Nav.Link>
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>About Us</Nav.Link>
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>Testimonials</Nav.Link>
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>Features</Nav.Link>
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>Contact</Nav.Link>
                    <Nav.Link className="nav-items" style={{fontWeight:600,color:"#6a7489"}}>Login</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
    </>
  );
};

export default Navigation;
