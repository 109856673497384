import React from "react";

const WhyUs = () => {
  return (
    <>
      <div className="container-fluid" style={{ background: "#f9f9fa" }}>
        <div className="container">
          <div
            className="row"
            style={{ rowGap: "0.8rem", padding: "64px 0px" }}
          >
            <div className="col-12 text-center pb-4">
              <h1 className="about-section-heading">Why Choose Us</h1>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 py-3 why-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgb(22, 223, 126)" }}
              >
                <path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z"></path>
                <path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z"></path>
              </svg>
              <h4
                style={{
                  fontWeight: 600,
                  marginBottom: "15px",
                  marginTop: "15px",
                  fontSize: "18px",
                  color: "#5f687b",
                }}
              >
                UNAUTHORIZED REPO CHARGES
              </h4>
              <p style={{ fontSize: "14px" }}>
                Our system provide real tracking of the repo agent activities
                which helps agency owner to track their activity and avoid
                unauthorized repo charges.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 py-3 why-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgb(22, 223, 126)" }}
              >
                <path d="M19 8h-3V5c0-1.103-.897-2-2-2H5c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h3v3c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2zm-4 7H9V9h6v6z"></path>
              </svg>
              <h4
                style={{
                  fontWeight: 600,
                  marginBottom: "15px",
                  marginTop: "15px",
                  fontSize: "18px",
                  color: "#5f687b",
                }}
              >
                RECOVERY AGENTS TRACKING
              </h4>
              <p style={{ fontSize: "14px" }}>
                Provide repo agent data which helps to come up with the
                efficiency of repo agents. Last 24 hours data can be tracked in
                one single snapshot with real data.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 py-3 why-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgb(22, 223, 126)" }}
              >
                <path d="M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396 1.414-1.414-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"></path>
              </svg>
              <h4
                style={{
                  fontWeight: 600,
                  marginBottom: "15px",
                  marginTop: "15px",
                  fontSize: "18px",
                  color: "#5f687b",
                }}
              >
                DATA ANALYSIS
              </h4>
              <p style={{ fontSize: "14px" }}>
                Provide the consolidated reports to the agency owner like
                vehicle search report of Repo Agents, accounting details
                snapshot on the dashboard etc. All data is stored on cloud hence
                can be accessed anytime, anywhere and after many years.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 py-3 why-card">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ fill: "rgb(22, 223, 126)" }}
              >
                <path d="M12 2C9.243 2 7 4.243 7 7v3H6c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8c0-1.103-.897-2-2-2h-1V7c0-2.757-2.243-5-5-5zm6 10 .002 8H6v-8h12zm-9-2V7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9z"></path>
              </svg>
              <h4
                style={{
                  fontWeight: 600,
                  marginBottom: "15px",
                  marginTop: "15px",
                  fontSize: "18px",
                  color: "#5f687b",
                }}
              >
                DATA SECURITY
              </h4>
              <p style={{ fontSize: "14px" }}>
                Sharing the vehicle details to authorized repo agencies will
                happen through Recovery Masters Software, so that Authorized
                agencies will not be able to extract this data out of this
                platform. And this will stop the data sharing between
                unauthorized agencies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
