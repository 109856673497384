import React from "react";
import CountUp from 'react-countup';

const Counter = () => {
  return (
    <>
      <div className="container">
        <div className="row" style={{rowGap:"20px",padding:"62px 0px"}} >
          <div className="col-lg-4  col-md-4 col-sm-12">
            <div className="d-flex justify-content-center" >
            <div className="text-center" >
            <CountUp end={300} duration={3} style={{color:"#16df7e",fontWeight:700,fontSize:"48px"}} /> +
            <p  className="mb-0" style={{fontFamily:"Raleway",fontSize:"1rem"}} >Total Active Customers</p>
            </div>
            </div>
          </div>
          <div className="col-lg-4  col-md-4 col-sm-12">
            <div className="d-flex justify-content-center" >
            <div className="text-center" >
            <CountUp end={1500} duration={3} style={{color:"#16df7e",fontWeight:700,fontSize:"48px"}} /> +
            <p  className="mb-0" style={{fontFamily:"Raleway",fontSize:"1rem"}} >Total Active Users</p>
            </div>
            </div>
          </div>
          <div className="col-lg-4  col-md-4 col-sm-12">
            <div className="d-flex justify-content-center" >
            <div className="text-center" >
            <CountUp end={20} duration={3} style={{color:"#16df7e",fontWeight:700,fontSize:"48px"}} /> +
            <p  className="mb-0" style={{fontFamily:"Raleway",fontSize:"1rem"}} >State Wise Coverage</p>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Counter;
