import React from "react";
import "../../index.css";

const Features = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12" style={{padding:"62px 0px"}} >
            <h1 className="features-section-heading text-center">Features</h1>
            <div className="container">
              <div className="row" style={{rowGap:"0.9rem"}} >
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: "#16df7e"}}><path d="M12 4C6.486 4 2 8.486 2 14a9.89 9.89 0 0 0 1.051 4.445c.17.34.516.555.895.555h16.107c.379 0 .726-.215.896-.555A9.89 9.89 0 0 0 22 14c0-5.514-4.486-10-10-10zm7.41 13H4.59A7.875 7.875 0 0 1 4 14c0-4.411 3.589-8 8-8s8 3.589 8 8a7.875 7.875 0 0 1-.59 3z"></path><path d="M10.939 12.939a1.53 1.53 0 0 0 0 2.561 1.53 1.53 0 0 0 2.121-.44l3.962-6.038a.034.034 0 0 0 0-.035.033.033 0 0 0-.045-.01l-6.038 3.962z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Perfect Dashboard</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >We provide you with the detailed dashboard to have the track access of all the user activity.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style={{fill: "#16df7e"}}><path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Accounting</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >Make you own invoice of the vehicle recovery with us.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style={{fill: "#16df7e"}}><path d="m1.293 8.395 1.414 1.414c.504-.504 1.052-.95 1.622-1.359L2.9 7.021c-.56.422-1.104.87-1.607 1.374zM6.474 5.06 3.707 2.293 2.293 3.707l18 18 1.414-1.414-5.012-5.012.976-.975a7.86 7.86 0 0 0-4.099-2.148L11.294 9.88c2.789-.191 5.649.748 7.729 2.827l1.414-1.414c-2.898-2.899-7.061-3.936-10.888-3.158L8.024 6.61A13.366 13.366 0 0 1 12 6c3.537 0 6.837 1.353 9.293 3.809l1.414-1.414C19.874 5.561 16.071 4 12 4a15.198 15.198 0 0 0-5.526 1.06zm-2.911 6.233 1.414 1.414a9.563 9.563 0 0 1 2.058-1.551L5.576 9.697c-.717.451-1.395.979-2.013 1.596zm2.766 3.014 1.414 1.414c.692-.692 1.535-1.151 2.429-1.428l-1.557-1.557a7.76 7.76 0 0 0-2.286 1.571zm7.66 3.803-2.1-2.1a1.996 1.996 0 1 0 2.1 2.1z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Mobile App in Offline</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >Sync once and work without even internet connection turned on.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style={{fill: "#16df7e"}}><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zM4 12c0-.899.156-1.762.431-2.569L6 11l2 2v2l2 2 1 1v1.931C7.061 19.436 4 16.072 4 12zm14.33 4.873C17.677 16.347 16.687 16 16 16v-1a2 2 0 0 0-2-2h-4v-3a2 2 0 0 0 2-2V7h1a2 2 0 0 0 2-2v-.411C17.928 5.778 20 8.65 20 12a7.947 7.947 0 0 1-1.67 4.873z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Data Analytics</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >We offer you with the best data analytical features like never before.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style={{fill: "#16df7e"}}><path d="M20 3H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h7v3H8v2h8v-2h-3v-3h7c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 15V5h16l.001 10H4z"></path><path d="m10 13 5-3-5-3z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Tracking Agents Activity</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >Track all the activities of your registered agents with App.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card p-2 py-5 h-100 d-flex align-items-center text-center border-0 h-100" style={{cursor:"pointer"}} > 

                        <div style={{color:"#16df7e"}} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"  style={{fill: "#16df7e"}}><path d="M12 2C9.243 2 7 4.243 7 7v3H6a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-1V7c0-2.757-2.243-5-5-5zM9 7c0-1.654 1.346-3 3-3s3 1.346 3 3v3H9V7zm4 10.723V20h-2v-2.277a1.993 1.993 0 0 1 .567-3.677A2.001 2.001 0 0 1 14 16a1.99 1.99 0 0 1-1 1.723z"></path></svg>
                        </div>

                        <h2 style={{color:"#5f687b",fontSize:"1.4rem"}} >Online Payment Gateway</h2>

                        <p className="mb-0" style={{fontSize:"14px"}} >Agents can directly make their monthly payment recharges, themselves through their App.</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
