import React from 'react';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    const Navigate = useNavigate();
  return (
    <>
      <div className="container mt-5">
        <IoIosArrowRoundBack style={{fontSize:"2rem",cursor:"pointer"}} className='mb-2' onClick={()=>Navigate("/")} />
        <p className="text-muted">Note: This Recovery Masters policy is applicable to Recovery Masters and other Recovery Agency Apps released by WE RNS IT SOLUTIONS PVT LTD.</p>
        
        <h1 className="mb-4">Recovery Masters & Recovery Apps Privacy Policy</h1>
        <p><strong>Effective Date:</strong> 22 Aug. 23</p>
        <h2>Introduction</h2>
        <p>Welcome to the Recovery Masters App ("App"), developed and operated by WE RNS IT SOLUTIONS PVT LTD ("we", "us", or "our"). This Privacy Policy outlines our practices concerning the collection, use, disclosure, and protection of your personal information when you use the Easy Recovery App. By accessing or using the App, you agree to the terms outlined in this Privacy Policy.</p>
        
        <h2>Information We Collect</h2>
        <p>We may collect the following types of personal information from you:</p>
        <ol>
            <li>User Information: When you register for an account or use our App, we may collect personal information such as your name, contact details, address, and identification details.</li>
            <li>Loan Information: In order to facilitate the loan recovery process, we may collect information about your loans, including loan amounts, outstanding balances, repayment histories, and related account details.</li>
            <li>Location Information: Our App operates only in India, and we may collect your device's location information to ensure compliance with Indian loan recovery guidelines and regulations.</li>
            <li>Communication Data: We may collect communication data, including call and message logs, to assist in the loan recovery process and maintain a record of interactions.</li>
        </ol>
        
        <h2>How We Use Your Information</h2>
        <p>We may use the collected information for the following purposes:</p>
        <ol>
            <li>Facilitate Loan Recovery: We use your personal information to assist in the recovery of outstanding loans in compliance with Indian loan recovery guidelines.</li>
            <li>Communication: We may contact you via email, phone, or other communication channels to provide updates on loan recovery progress and related matters.</li>
            <li>Legal Compliance: We process your information to adhere to applicable laws, regulations, and guidelines governing loan recovery operations in India.</li>
            <li>Improve Services: We analyze usage patterns and customer feedback to improve the functionality and user experience of the App.</li>
        </ol>
        
        <h2>Disclosure of Your Information</h2>
        <p>We may share your personal information with:</p>
        <ol>
            <li>Service Providers: We may engage third-party service providers to assist in loan recovery efforts, and they may have access to your personal information.</li>
            <li>Legal Authorities: We may share information with law enforcement agencies, courts, or other government authorities as required by law or to enforce our legal rights.</li>
            <li>Affiliates: We may share information with our affiliates and group companies for business and operational purposes.</li>
        </ol>
        
        <h2>Your Choices</h2>
        <p>You have the following choices regarding your personal information:</p>
        <ol>
            <li>Access and Correction: You can access and update your personal information through your account settings in the App.</li>
            <li>Withdraw Consent: You can withdraw your consent for us to process your personal information by deleting your account.</li>
            <li>Data Deletion: You can request the deletion of your personal information by contacting us at <a href="mailto:info@rnsitsolutions.com">info@rnsitsolutions.com</a>.</li>
        </ol>
        
        <h2>Data Security</h2>
        <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of data transmission over the internet is entirely secure, and we cannot guarantee its absolute security.</p>
        
        <h2>Contact Us</h2>
        <p>If you have any questions or concerns regarding this Privacy Policy or our data practices, please contact us at:</p>
        <address>
            WE RNS IT SOLUTIONS PVT LTD<br />
            101 Chanda Tower, Vaishali Nagar, Jaipur - 302021<br />
            <a href="mailto:info@rnsitsolutions.com">info@rnsitsolutions.com</a>
        </address>
        
        <h2>Changes to this Privacy Policy</h2>
        <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. The updated Privacy Policy will be posted on our website and within the App, and the effective date will be revised accordingly.</p>
        <p>By using the Easy Recovery App, you acknowledge and agree to the terms outlined in this Privacy Policy.</p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
